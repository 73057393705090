<template>
  <b-row>
    <b-col sm="12">
      <b-table v-if="value.vehicles.length > 0" :small="true" hover :items="value.vehicles" :fields="fields" @row-clicked="edit" foot-clone>
        <template v-slot:cell(startAt)="data">
          <date :value="data.value" :showDay="false" format="MM/YYYY" default="Existing"></date>
        </template>
        <template v-slot:cell(balance)="data">
          <dollars :value="data.value"></dollars>
        </template>
        <template v-slot:cell(minimumPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(actualPayment)="data">
          <dollars :value="data.value" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:cell(rate)="data">
          <percent :value="data.value"></percent>
        </template>
        <template v-slot:cell(delete)="data">
          <div class="text-right">
            <b-button size="sm" variant="ghost-danger" v-on:click="remove(data.item)"><i class="fa fa-remove"></i></b-button>
          </div>
        </template>
        <template v-slot:foot(make)><span></span></template>
        <template v-slot:foot(model)><span></span></template>
        <template v-slot:foot(year)><span></span></template>
        <template v-slot:foot(startAt)><span></span></template>
        <template v-slot:foot(balance)>
          <dollars :value="totalBalance"></dollars>
        </template>
        <template v-slot:foot(minimumPayment)>
          <dollars :value="totalMinimumPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(actualPayment)>
          <dollars :value="totalActualPayment" :postfix="'mo'"></dollars>
        </template>
        <template v-slot:foot(rate)><span></span></template>
        <template v-slot:foot(months)><span></span></template>
      </b-table>

      <!-- Add Vehicle -->
      <b-button size="sm" variant="outline-success" v-on:click="add()">
        <i class="fa fa-plus"></i>&nbsp;Add Vehicle
      </b-button>
      <vehicle-modal @save="save"></vehicle-modal>
    </b-col>
  </b-row>
</template>
<script>
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

import VehicleModal from '@/components/modals/VehicleModal'

export default {
  props: [ 'value' ],
  components: {
    VehicleModal,
    Date,
    Dollars,
    Percent
  },
  computed: {
    totalBalance: function () {
      let total = 0.0
      this.value.vehicles.forEach(function(item) { total += parseFloat(item.balance) })
      return total
    },
    totalMinimumPayment: function () {
      let total = 0.0
      this.value.vehicles.forEach(function(item) { total += parseFloat(item.minimumPayment) })
      return total
    },
    totalActualPayment: function () {
      let total = 0.0
      this.value.vehicles.forEach(function(item) { total += parseFloat(item.actualPayment) })
      return total
    }
  },
  data () {
    return {
      fields: [
        { key: 'make' },
        { key: 'model' },
        { key: 'year' },
        { key: 'startAt', label: 'Timeframe' },
        { key: 'balance', class: "numeric" },
        { key: 'minimumPayment', class: 'numeric', label: 'Minimum Payment' },
        { key: 'actualPayment', class: 'numeric', label: 'Actual Payment' },
        { key: 'rate', class: "numeric" },
        { key: 'months', class: "numeric" },
        { key: 'delete', label: '', class: 'delete' }
      ],
      items: []
    }
  },
  methods: {
    add () {
      this.$bus.$emit('modal:editVehicle')
    },
    edit (item) {
      let index = this.value.vehicles.indexOf(item)
      this.editing = index
      this.$bus.$emit('modal:editVehicle', item)
    },
    remove (item) {
      this.$api.vehicles.delete(item.id)
        .then(() => {
          let index = this.value.vehicles.indexOf(item)
          this.value.vehicles.splice(index, 1)
          this.$snotify.success('Vehicle Deleted Successfully')
        })
        .catch((err) => {
          this.$snotify.error('There was a problem removing the policy.')
        })
    },
    save (value) {
      value.clientId = this.value.id
      if (value.id) {
        this.$api.vehicles.update(value.id, value)
          .then((response) => {
            this.value.vehicles.splice(this.editing, 1, response.data)
            this.editing = null
            this.$snotify.success('Vehicle Saved Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Vehicle.')
          })
      } else {
        this.$api.vehicles.create(value)
          .then((response) => {
            this.value.vehicles.push(response.data)
            this.$snotify.success('Vehicle Added Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Vehicle.')
          })
      }
    }
  }
}
</script>

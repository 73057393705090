<template>
  <b-modal :title="loan.id > 0 ? 'Edit Loan' : 'Add Loan'" size="lg" ok-title="Save" v-model="active" @ok="save">
    <b-row class="form-group">
      <b-col sm="5">
        <label>Type</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. Student, Family, Store..." v-model="loan.name" :state="state($v.loan.name)" @input="touch($v.loan.name)" />
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="5">
        <label>Remaining Balance</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="loan.balance" :state="state($v.loan.balance)" @input="touch($v.loan.balance)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="2">
        <label>Interest Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="loan.rate" :state="state($v.loan.rate)" @input="touch($v.loan.rate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Valid Decimals Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <label>Minimum Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 500.00" v-model="loan.minimumPayment" :state="state($v.loan.minimumPayment)" @input="touch($v.loan.minimumPayment)" />
        <b-form-invalid-feedback>Decimals Only. i.e. 100.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Actual Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 500.00" v-model="loan.actualPayment" :state="state($v.loan.actualPayment)" @input="touch($v.loan.actualPayment)" />
        <b-form-invalid-feedback>Required. i.e. 100.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Total Length <small>(Years)</small></label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 30" v-model="loan.years" :state="state($v.loan.years)" @input="touch($v.loan.years)"/>
        <b-form-invalid-feedback>Decimals Only</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col class="form-group" sm="3">
        <label v-b-tooltip.hover title="Use Future if planning on adding a debt in the future">Timeframe <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="timeframeOptions" v-model="timeframe"></b-form-select>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Doesn't have to be exact. Month/Year if possible.">Start Date <i class="fa fa-info-circle"></i></label>
        <flat-pickr class="form-control" v-model.trim="loan.startAt" :config="dateConfig" placeholder="Start Date"></flat-pickr>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Use new money or reduce windcurrent for payment source">Payment Source <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="sourceOptions" v-model="loan.newMoney"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { decimal, required } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  name: 'LoanModal',
  components: {
    flatPickr
  },
  data () {
    return {
      timeframe: null,
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      timeframeOptions: [
        { value: null, text: 'Existing' },
        { value: 'future', text: 'Future' }
      ],
      sourceOptions: [
        { value: true, text: 'New Money' },
        { value: false, text: 'Wind Current' }
      ],
      loan: {
        id: null,
        name: null,
        startAt: null,
        balance: null,
        payment: null,
        rate: null,
        years: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (loan) {
      this.active = true
      if (loan) {
        this.loan = { ...loan }
      } else {
        this.loan = {
          id: null,
          name: null,
          startAt: null,
          balance: null,
          payment: null,
          rate: null,
          years: null
        }
      }
      if (this.loan.startAt) {
        this.timeframe = 'future'
      } else {
        this.timeframe = null
      }
    },
    save (e) {
      this.loan.rate = this.loan.rate || 0.0
      this.loan.minimumPayment = this.loan.minimumPayment || 0.0
      this.loan.years = this.loan.years || 0.0

      if (!this.timeframe || moment(this.loan.startAt) <= moment()) {
        this.loan.startAt = null
      }

      if (!this.$v.$invalid) {
        this.$emit('save', { ...this.loan })
      } else {
        e.preventDefault()
        this.$v.$touch()
      }
    }
  },
  created() {
    this.$bus.$on('modal:editLoan', (loan) => {
      this.$v.$reset()
      this.show(loan)
    });
  },
  validations: {
    loan: {
      name: {
        required
      },
      balance: {
        required,
        decimal
      },
      rate: {
        decimal
      },
      minimumPayment: {
        decimal
      },
      actualPayment: {
        required,
        decimal
      },
      years: {
        decimal
      }
    }
  }
}
</script>

<template>
  <b-modal :title="mortgage.id > 0 ? 'Edit Mortgage' : 'Add Mortgage'" size="lg" v-model="active" ok-title="Save" @ok="save">
    <b-row class="form-group">
      <b-col sm="3">
        <label>Name</label>
        <b-form-input type="text" class="form-control" placeholder="123 Street." v-model="mortgage.name" :state="state($v.mortgage.name)" @input="touch($v.mortgage.name)" />
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="3">
        <label>Type</label>
        <b-form-select :options="mortgageTypes" v-model="mortgage.typeId" :state="state($v.mortgage.typeId)" @change="touch($v.mortgage.typeId)"></b-form-select>
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Remaining Balance</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="mortgage.balance" :state="state($v.mortgage.balance)" @input="touch($v.mortgage.balance)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="2">
        <label>Interest Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="mortgage.rate" :state="state($v.mortgage.rate)" @input="touch($v.mortgage.rate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Decimals Only. i.e. 100.00</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <label>Minimum Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 500.00" v-model="mortgage.minimumPayment" :state="state($v.mortgage.minimumPayment)" @input="touch($v.mortgage.minimumPayment)" />
        <small>Payment and Interest</small>
        <b-form-invalid-feedback>Decimals Only. i.e. 100.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Actual Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 500.00" v-model="mortgage.actualPayment" :state="state($v.mortgage.actualPayment)" @input="touch($v.mortgage.actualPayment)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Total Length <small>(Years)</small></label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 30" v-model="mortgage.years" :state="state($v.mortgage.years)" @input="touch($v.mortgage.years)" />
        <b-form-invalid-feedback>Decimals Only</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col class="form-group" sm="3">
        <label v-b-tooltip.hover title="Use Future if planning on adding a debt in the future">Timeframe <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="timeframeOptions" v-model="timeframe"></b-form-select>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Doesn't have to be exact. Month/Year if possible.">Start Date <i class="fa fa-info-circle"></i></label>
        <flat-pickr class="form-control" v-model.trim="mortgage.startAt" :config="dateConfig" placeholder="Start Date"></flat-pickr>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Use new money or reduce windcurrent for payment source">Payment Source <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="sourceOptions" v-model="mortgage.newMoney"></b-form-select>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="form-group" sm="4">
        <label>Is this connected to an Asset?</label>
        <b-form-select :options="assetOptions" v-model="mortgage.assetId"></b-form-select>
      </b-col>
      <b-col sm="4" v-if="mortgage.assetId">
        <label>Type</label>
        <b-form-select :options="assetTypeOptions" v-model="asset.typeId" :state="state($v.asset.typeId)" @change="touch($v.asset.typeId)"></b-form-select>
        <b-form-invalid-feedback>Required.</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4" v-if="mortgage.assetId">
        <label>Current Value</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="asset.balance" :state="state($v.asset.balance)" @input="touch($v.asset.balance)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { decimal, required } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

const ifAsset = (validator) => {
  return (value, vm) => {
    return true
    // if (vm.mort) {
    //   return validator(value, vm)
    // } else {
    //   return true
    // }
  }
}
let assetTypes = []
let mortgageTypes = []

export default {
  name: 'MortgageModal',
  components: {
    flatPickr
  },
  computed: {
    ...mapGetters({
      assetTypes: 'global/assetTypes'
    }),
    assetTypeOptions () {
      return [{ value: null, text: 'Select Type...', disabled: true }].concat(
        this.assetTypes.filter(o => !o.canContribute).map(o => ({ value: o.id, text: o.name }))
      )
    }
  },
  data () {
    return {
      timeframe: null,
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      timeframeOptions: [
        { value: null, text: 'Existing' },
        { value: 'future', text: 'Future' }
      ],
      sourceOptions: [
        { value: true, text: 'New Money' },
        { value: false, text: 'Wind Current' }
      ],
      assetOptions: [],
      assets: [],
      mortgageTypes: [
        { value: null, text: 'Select Type...', disabled: true }
      ],
      asset: {
        id: null,
        type: null,
        typeId: null,
        balance: null,
        monthlyContribution: null
      },
      mortgage: {
        type: null,
        typeId: null,
        startAt: null,
        assetId: null,
        balance: null,
        payment: null,
        insurance: null,
        rate: null,
        length: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (mortgage) {
      this.active = true
      if (mortgage) {
        this.mortgage = { ...mortgage }
      } else {
        this.mortgage = {
          id: null,
          type: null,
          typeId: null,
          assetId: null,
          startAt: null,
          balance: null,
          payment: null,
          rate: null,
          length: null
        }
      }
      if (this.mortgage.startAt) {
        this.timeframe = 'future'
      } else {
        this.timeframe = null
      }
    },
    save (e) {
      this.mortgage.rate = this.mortgage.rate || 0.0
      this.mortgage.minimumPayment = this.mortgage.minimumPayment || 0.0
      this.mortgage.years = this.mortgage.years || 0.0

      if (!this.timeframe || moment(this.mortgage.startAt) <= moment()) {
        this.mortgage.startAt = null
      }

      if (!this.$v.$invalid) {
        this.asset.type = assetTypes.find((e) => { return e.id == this.asset.typeId } )
        this.mortgage.type = mortgageTypes.find((e) => { return e.id == this.mortgage.typeId } )
        this.$emit('save', { ...this.mortgage }, { ...this.asset })
      } else {
        e.preventDefault()
        this.$v.$touch()
      }
    }
  },
  created() {
    this.$bus.$on('modal:editMortgage', (mortgage, assets) => {
      this.show(mortgage)

      this.assetOptions = [
        { value: null, text: 'No Asset' },
        { value: 'new', text: 'Add New Asset' }
      ]
      this.assets = assets
      assets.forEach((asset) => {
        if (!asset.type.canContribute) {
          const name = asset.name || asset.type.name
          this.assetOptions.push({
            value: asset.id,
            text: `${name} (${asset.balance})`
          })
        }
      })
    })

    this.$api.mortgages.types()
      .then((response) => {
        mortgageTypes = response.data

        mortgageTypes.forEach((type) => {
          this.mortgageTypes.push({ value: type.id, text: type.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  watch: {
    'mortgage.typeId': function (value) {
      this.mortgage.type = mortgageTypes.find((e) => { return e.id == this.mortgage.typeId } )
    },
    'mortgage.assetId': function (value) {
      if (value === 'new') {
        this.asset = {
          id: null,
          type: null,
          typeId: null,
          balance: null,
          monthlyContribution: null
        }
      } else if (value > 0) {
        let index = this.assets.findIndex((o) => o.id === value)
        if (index >= 0) {
          this.asset = this.assets[index]
        }
      }
    },
    'asset.typeId': function (value) {
      this.asset.type = assetTypes.find((e) => { return e.id == this.asset.typeId } )
    }
  },
  validations: {
    mortgage: {
      name: {
        required
      },
      typeId: {
        required
      },
      balance: {
        required,
        decimal
      },
      rate: {
        decimal
      },
      minimumPayment: {
        decimal
      },
      actualPayment: {
        required,
        decimal
      },
      years: {
        decimal
      }
    },
    asset: {
      typeId: {
        required: ifAsset(required)
      },
      balance: {
        required: ifAsset(required)
      }
    }
  }
}
</script>

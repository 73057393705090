<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col sm="12">
          <b-table v-if="value.loans.length > 0" :small="true" hover :items="value.loans" :fields="fields" @row-clicked="edit" foot-clone>
            <template v-slot:cell(startAt)="data">
              <date :value="data.value" :showDay="false" format="MM/YYYY" default="Existing"></date>
            </template>
            <template v-slot:cell(balance)="data">
              <dollars :value="data.value"></dollars>
            </template>
            <template v-slot:cell(minimumPayment)="data">
              <dollars :value="data.value" :postfix="'mo'"></dollars>
            </template>
            <template v-slot:cell(actualPayment)="data">
              <dollars :value="data.value" :postfix="'mo'"></dollars>
            </template>
            <template v-slot:cell(rate)="data">
              <percent :value="data.value"></percent>
            </template>
            <template v-slot:cell(delete)="data">
              <div class="text-right">
                <b-button size="sm" variant="ghost-danger" @click.stop="remove(data.item)"><i class="fa fa-remove"></i></b-button>
              </div>
            </template>
            <template v-slot:foot(name)><span></span></template>
            <template v-slot:foot(startAt)><span></span></template>
            <template v-slot:foot(balance)>
              <dollars :value="totalBalance"></dollars>
            </template>
            <template v-slot:foot(minimumPayment)>
              <dollars :value="totalMinimumPayment" :postfix="'mo'"></dollars>
            </template>
            <template v-slot:foot(actualPayment)>
              <dollars :value="totalActualPayment" :postfix="'mo'"></dollars>
            </template>
            <template v-slot:foot(rate)><span></span></template>
            <template v-slot:foot(years)><span></span></template>
          </b-table>
          <b-button size="sm" variant="outline-success" @click="add()">
            <i class="fa fa-plus"></i>&nbsp;Add Loan/Debt
          </b-button>
          <loan-modal v-on:save="save"></loan-modal>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import LoanModal from '@/components/modals/LoanModal'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

export default {
  props: [ 'value' ],
  components: {
    LoanModal,
    Date,
    Dollars,
    Percent
  },
  computed: {
    totalBalance: function () {
      let total = 0.0
      this.value.loans.forEach(function(item) { total += parseFloat(item.balance) })
      return total
    },
    totalMinimumPayment: function () {
      let total = 0.0
      this.value.loans.forEach(function(item) { total += parseFloat(item.minimumPayment) })
      return total
    },
    totalActualPayment: function () {
      let total = 0.0
      this.value.loans.forEach(function(item) { total += parseFloat(item.actualPayment) })
      return total
    }
  },
  data () {
    return {
      fields: [
        { key: 'name', label: 'Type' },
        { key: 'startAt', label: 'Timeframe' },
        { key: 'balance', class: "numeric" },
        { key: 'minimumPayment', class: 'numeric', label: 'Minimum Payment' },
        { key: 'actualPayment', class: 'numeric', label: 'Actual Payment' },
        { key: 'rate', class: "numeric" },
        { key: 'years', class: "numeric" },
        { key: 'delete', label: '', class: 'delete' }
      ],
      items: [],
      editing: null
    }
  },
  methods: {
    add () {
      this.$bus.$emit('modal:editLoan')
    },
    edit (item) {
      let index = this.value.loans.indexOf(item)
      this.editing = index
      this.$bus.$emit('modal:editLoan', item)
    },
    remove (item) {
      this.$api.loans.delete(item.id)
        .then(() => {
          let index = this.value.loans.indexOf(item)
          this.value.loans.splice(index, 1)
          this.$snotify.success('Loan Deleted Successfully')
        })
        .catch((err) => {
          this.$snotify.error('There was a problem removing the policy.')
        })
    },
    save (value) {
      value.clientId = this.value.id
      if (value.id) {
        this.$api.loans.update(value.id, value)
          .then((response) => {
            this.value.loans.splice(this.editing, 1, response.data)
            this.editing = null
            this.$snotify.success('Loan Saved Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Loan.')
          })
      } else {
        this.$api.loans.create(value)
          .then((response) => {
            this.value.loans.push(response.data)
            this.$snotify.success('Loan Added Successfully')
          })
          .catch((err) => {
            this.$snotify.error('There was a problem saving the Loan.')
          })
      }
    }
  }
}
</script>

<template>
  <b-modal title="Existing Life Insurance" size="lg" v-model="active" @ok="save">
    <b-row class="form-group">
      <b-col sm="4" v-if="contacts">
        <label>Policy Holder</label>
        <b-form-select :options="contactOptions" v-model="lifeInsurance.contactId"></b-form-select>
      </b-col>
      <b-col sm="4">
        <label>Name</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. ABC Life" v-model="lifeInsurance.name" />
      </b-col>
      <b-col sm="4">
        <label>Start Date</label>
        <date :value="lifeInsurance.startedAt" :showDay="false" @update="startedAt"></date>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <label>Type</label>
        <b-form-select :options="types" v-model="lifeInsurance.typeId" :state="state($v.lifeInsurance.typeId)" @input="touch($v.lifeInsurance.typeId)"></b-form-select>
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label for="paymentType">Payment Type</label>
        <b-form-select :options="paymentTypes" v-model="lifeInsurance.paymentType" :state="state($v.lifeInsurance.paymentType)" @input="touch($v.lifeInsurance.paymentType)"></b-form-select>
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Premium</label>
        <b-input-group class="rate">
          <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="premium" />
          <b-input-group-append><b-input-group-text>{{ typeSuffix }}</b-input-group-text></b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <label>Policy Face Value</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="lifeInsurance.faceValue" />
      </b-col>
      <b-col sm="4" v-if="lifeInsurance.type && lifeInsurance.type.hasCashValue">
        <label v-b-tooltip.hover title="Enter Total Cash Value (Don't subtract any loans)">Cash Value <i class="fa fa-info-circle"></i></label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="lifeInsurance.cashValue" />
      </b-col>
      <b-col sm="4" v-if="lifeInsurance.type && lifeInsurance.type.canBorrow">
        <label>Loan Balance</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 10000.00" v-model="lifeInsurance.loanAmount" />
      </b-col>
      <b-col sm="4" v-if="lifeInsurance.type && lifeInsurance.type.hasTerm">
        <label>Policy Start Date</label>
        <flat-pickr class="form-control" v-model.trim="lifeInsurance.termStartDate" :config="dateConfig" placeholder="Start Date"></flat-pickr>
      </b-col>
      <b-col sm="4" v-if="lifeInsurance.type && lifeInsurance.type.hasTerm">
        <label>Term Duration</label>
        <b-form-select :options="termDurationOptions" v-model="lifeInsurance.termDuration"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { required } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import Date from '@/components/inputs/Date'

let types = []

export default {
  name: 'LifeInsuranceModal',
  props: [ 'id', 'contacts' ],
  components: { flatPickr, Date },
  data () {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      types: [
        { value: null, text: 'Select Type...', disabled: true }
      ],
      // contactOptions: [
      //   { value: null, text: 'Select Holder...', disabled: true }
      // ],
      paymentTypes: [
        { value: 'annual', text: 'Annual' },
        // { value: 'semi-annual', text: 'Semi-Annual' },
        // { value: 'quarterly', text: 'Quarterly' },
        { value: 'monthly', text: 'Monthly' }
      ],
      termDurationOptions: [
        { value: 10, text: '10 Years' },
        { value: 15, text: '15 Years' },
        { value: 20, text: '20 Years' },
        { value: 25, text: '25 Years' },
        { value: 30, text: '30 Years' },
      ],
      lifeInsurance: {
        id: null,
        contactId: null,
        type: {
          hasCashValue: false,
          canBorrow: false
        },
        typeId: null,
        faceValue: 0.0,
        paymentType: 'annual',
        annualPremium: 0.0,
        cashValue: 0.0,
        loanAmount: 0.0,
        startedAt: moment().format('YYYY-MM-DD'),
      },
      active: false,
      premium: 0.0
    }
  },
  methods: {
    startedAt(value) {
      this.lifeInsurance.startedAt = value
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (lifeInsurance) {
      this.active = true
      if (lifeInsurance) {
        this.lifeInsurance = { ...lifeInsurance }
      } else {
        this.lifeInsurance = {
          id: null,
          contactId: null,
          type: null,
          typeId: null,
          faceValue: 0.0,
          paymentTypes: 'annual',
          annualPremium: 0.0,
          cashValue: 0.0,
          loanAmount: 0.0,
          startedAt: moment().format('YYYY-MM-DD')
        }
      }
    },
    save (e) {
      if (!this.$v.$invalid) {
        this.$emit('save', { ...this.lifeInsurance });
      } else {
        e.preventDefault()
        this.$v.$touch()
      }

    },
    calculateAnnualPremium () {
      switch (this.lifeInsurance.paymentType) {
        case 'semi-annual':
          this.lifeInsurance.annualPremium = this.premium * 2
          break
        case 'quarterly':
          this.lifeInsurance.annualPremium = this.premium * 4
          break
        case 'monthly':
          this.lifeInsurance.annualPremium = this.premium * 12
          break
        default:
        case 'annual':
          this.lifeInsurance.annualPremium = this.premium
      }
    }
  },
  computed: {
    typeSuffix () {
      let suffix = '/yr'
      switch (this.lifeInsurance.paymentType) {
        case 'semi-annual':
          suffix = '/6mo'
          break
        case 'quarterly':
          suffix = '/qtr'
          break
        case 'monthly':
          suffix = '/mo'
          break
        case 'annual':
          suffix = '/yr'
      }
      return suffix
    },
    contactOptions () {
      let options = []
      options.push({ value: null, text: 'Select Holder...', disabled: true })
      this.contacts.forEach((contact) => {
        if (contact.firstName && contact.lastName) {
          options.push({ value: contact.id, text: `${contact.firstName} ${contact.lastName}` })
        }
      })
      return options
    }
  },
  created () {
    this.$bus.$on('modal:editLifeInsurance', (lifeInsurance) => {
      this.show(lifeInsurance)

      switch (this.lifeInsurance.paymentType) {
        case 'semi-annual':
          this.premium = this.lifeInsurance.annualPremium / 2
          break
        case 'quarterly':
          this.premium = this.lifeInsurance.annualPremium / 4
          break
        case 'monthly':
          this.premium = this.lifeInsurance.annualPremium / 12
          break
        default:
        case 'annual':
          this.premium = this.lifeInsurance.annualPremium
      }
    });

    this.$api.policies.types()
      .then((response) => {
        types = response.data

        types.forEach((type) => {
          this.types.push({ value: type.id, text: type.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  watch: {
    'lifeInsurance.typeId': function (value) {
      const type = types.find((e) => { return e.id == this.lifeInsurance.typeId } )

      if (type) {
        this.lifeInsurance.type = type
      } else {
        this.lifeInsurance.type = {
          hasCashValue: false,
          canBorrow: false
        }
      }

    },
    'premium': function (value) {
      this.calculateAnnualPremium()
    },
    'lifeInsurance.paymentType': function (value) {
      this.calculateAnnualPremium()
    }
  },
  validations: {
    lifeInsurance: {
      typeId: {
        required
      },
      paymentType: {
        required
      }
    }
  }
}
</script>

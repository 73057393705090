<template>
  <b-row>
    <b-col sm="12">
      <p class="text-muted">Provide any additional comments to us here.</p>
      <b-row class="form-group" v-if="!editing">
        <b-col sm="12">
          <pre>{{ value }}</pre>
        </b-col>
      </b-row>
      <b-row class="form-group" v-if="editing">
        <b-col sm="12">
          <b-textarea :rows="6" :no-resize="true" v-model="comments"></b-textarea>
          <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="mt-3 text-right">
          <b-button variant="outline-warning" @click="edit()" v-if="!editing">Edit Comments</b-button>
          <b-button variant="success" @click="save()" v-if="editing">Save Comments</b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: [ 'value' ],
  data () {
    return {
      editing: false,
      comments: null
    }
  },
  computed: {
    formattedComments () {
      return this.value.replace(/\n/g, '<br />')
    }
  },
  methods: {
    save () {
      this.$emit('update', this.comments)
      this.editing = false
    },
    edit () {
      if (this.edit === true) {
        this.editing = false
      } else {
        this.editing = true
        this.comments = this.value
      }
    }
  }
}
</script>

<style>
  pre {
    font-family: inherit;
    font-size: 14px;
  }
</style>

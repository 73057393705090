<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col cols="12" xl="6">
          <b-card no-header border-variant="primary" class="clickable" @click="editContact(client.primaryContact)">
            <template slot="header">
              <b-row>
                <b-col sm="6">
                  Primary Contact Info
                </b-col>
                <b-col sm="6" class="text-right">
                  <b-button size="sm" variant="ghost-primary" @click="editContact(client.primaryContact)">
                    <i class="fa fa-pencil"></i> Edit
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col sm="6">
                <b-row>
                  <b-col sm="12">
                    <strong>{{ client.primaryContact.firstName }} {{ client.primaryContact.lastName }}</strong> <span class="ml-1" v-if="client.primaryContact.birthday">({{ client.primaryContact.age }})</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6" v-if="client.primaryContact.mobilePhone && client.primaryContact.mobilePhone.number">
                    <strong><i class="fa fa-mobile-phone"></i></strong> {{ client.primaryContact.mobilePhone.numberDashed }}
                  </b-col>
                  <b-col sm="6" v-if="client.primaryContact.homePhone && client.primaryContact.homePhone.number">
                    <strong><i class="fa fa-phone"></i></strong> {{ client.primaryContact.homePhone.numberDashed }}
                  </b-col>
                </b-row>
                <b-row v-if="currentClient.primaryContact.emailAddress">
                  <b-col sm="12">
                    <strong><i class="fa fa-envelope-o"></i></strong> {{ currentClient.primaryContact.emailAddress.address }}
                  </b-col>
                </b-row>
                <b-row v-if="client.primaryContact.birthday">
                  <b-col sm="12">
                    <strong><i class="fa fa-birthday-cake"></i></strong> {{ formatBirthday(client.primaryContact.birthday) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="6" v-if="client.primaryContact.address">
                <b-row>
                  <b-col sm="12" v-if="client.primaryContact.address.street">
                    {{ client.primaryContact.address.street }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" v-if="client.primaryContact.address.city || client.primaryContact.address.province || client.primaryContact.address.postalCode">
                    <span v-if="client.primaryContact.address.city">{{ client.primaryContact.address.city }},</span>
                    <span v-if="client.primaryContact.address.province"> {{ client.primaryContact.address.province.name }}</span>
                    <span v-if="client.primaryContact.address.postalCode"> {{ client.primaryContact.address.postalCode }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" v-if="client.primaryContact.address.country">
                    {{ client.primaryContact.address.country.name }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row v-if="client.primaryContact.income">
              <b-col sm="6" v-if="client.primaryContact.income.monthly > 0">
                <strong>Income: </strong><dollars :value="client.primaryContact.income.monthly" :postfix="'mo'"></dollars>
              </b-col>
              <b-col sm="6" v-if="client.primaryContact.income.additionalMonthly > 0">
                <strong>Add'l Income: </strong><dollars :value="client.primaryContact.income.additionalMonthly" :postfix="'mo'"></dollars>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-header border-variant="primary">
            <template slot="header">
              <b-row>
                <b-col sm="6">
                  Other Contacts
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col sm="12">
                <p class="text-muted" v-if="otherContacts.length == 0">No Other Contacts</p>
                <b-table hover :small="true" :items="otherContacts" :fields="otherContactFields" @row-clicked="editContact" v-if="otherContacts.length > 0"></b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <b-button size="sm" variant="success" @click="addContact()">
                  <i class="icon-plus"></i>&nbsp;Add Contact
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" xl="6">
          <b-card no-header border-variant="primary">
            <template slot="header">
              <b-row>
                <b-col sm="6">
                  Intake Request
                </b-col>
                <b-col sm="6" class="text-right" v-if="hasIntakeRequest">
                  <b-badge :variant="intakeRequest.status.style"><strong>Status:</strong> {{ intakeRequest.status.name }}</b-badge>
                </b-col>
              </b-row>
            </template>
            <b-row v-if="!hasIntakeRequest">
              <b-col sm="6">
                <strong>Status:</strong> Not Requested
              </b-col>
              <b-col sm="6" class="text-right">
                <b-button size="sm" v-if="!hasIntakeRequest" @click="requestIntake">Request Intake</b-button>
                <b-button size="sm" variant="success" class="ml-2" v-if="!hasIntakeRequest" @click="completeManualIntake">Mark Completed</b-button>
              </b-col>
            </b-row>
            <b-row v-if="hasIntakeRequest">
              <b-col sm="6">
                <span class="info"><strong>Created On: </strong><date :value="intakeRequest.createdAt" format="MM/DD/YYYY hh:mm a"></date></span>
                <span class="info" v-if="intakeEmailDate"><strong>Last Requested On: </strong><date :value="intakeEmailDate" format="MM/DD/YYYY hh:mm a"></date></span>
                <span class="info" v-if="intakeRequestedBy"><strong>Requested By: </strong>{{ intakeRequestedBy }}</span>
                <span class="info"><strong>Email Status: </strong>{{ intakeEmailStatus }}</span>
              </b-col>
              <b-col sm="6" class="text-right">
                <b-button size="sm" v-if="intakeRequest && intakeRequest.statusId <= 2" @click="resendIntake">Resend Intake</b-button>
                <b-button size="sm" variant="success" class="ml-2" v-if="intakeRequest && intakeRequest.statusId <= 2" @click="completeIntake">Mark Completed</b-button>
                <b-button size="sm" variant="success" v-if="$can('accept', 'intake') && intakeRequest && intakeRequest.statusId == 3" @click="acceptIntake">Accept Intake</b-button>
                <b-button size="sm" variant="info" v-if="$can('accept', 'intake') && intakeRequest && intakeRequest.statusId == 4" @click="reopenIntake">Reopen Intake</b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-header border-variant="primary">
            <template slot="header">
              <b-row>
                <b-col sm="6">
                  Existing Policies
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col sm="12">
                <b-table hover :small="true" :items="client.existingPolicies" :fields="existingPolicies.fields" @row-clicked="editPolicy" v-if="client.existingPolicies.length > 0">
                  <template v-slot:cell(name)="data">
                    {{ data.value }}<br />
                    <b-badge variant="primary" v-if="data.item.contact">{{ `${data.item.contact.firstName} ${data.item.contact.lastName}` }}</b-badge>
                  </template>
                  <template v-slot:cell(faceValue)="data">
                    <dollars :value="data.value"></dollars>
                  </template>
                  <template v-slot:cell(annualPremium)="data">
                    <dollars :value="data.value" :postfix="'yr'"></dollars>
                  </template>
                  <template v-slot:cell(contextual)="data">
                    <div v-if="data.item.type && data.item.type.hasCashValue"><strong>Cash Value:</strong> <dollars :value="data.item.cashValue"></dollars></div>
                    <div v-if="data.item.type && data.item.type.canBorrow"><strong>Loan Amount:</strong> <dollars :value="data.item.loanAmount"></dollars></div>
                    <div v-if="data.item.type && data.item.type.hasTerm && data.item.termStartDate && data.item.termDuration"><strong>End Date:</strong> {{ termEnd(data.item) }}</div>
                  </template>
                  <template v-slot:cell(delete)="data">
                    <div class="text-right">
                      <b-button size="sm" variant="ghost-danger" @click.stop="removePolicy(data.item)"><i class="fa fa-remove"></i></b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <b-button size="sm" variant="success" @click="addPolicy()">
                  <i class="icon-plus"></i>&nbsp;Add Policy
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-tabs>
            <b-tab active>
              <template slot="title">
                <i class="fa fa-money"></i> Assets
              </template>
              <assets v-model="client"></assets>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="icon-home"></i> Mortgages
              </template>
              <mortgages v-model="client"></mortgages>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="fa fa-automobile"></i> Vehicles
              </template>
              <vehicles v-model="client"></vehicles>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="fa fa-credit-card"></i> Credit Cards
              </template>
              <credit-cards v-model="client"></credit-cards>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="icon-pie-chart"></i> Loans
              </template>
              <loans v-model="client"></loans>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="icon-speech"></i> Comments
              </template>
              <comments v-model="client.comments" @update="saveComments"></comments>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <hr v-if="$can('delete', 'client') && account && client && account.organizationId == client.organizationId" />
      <b-row>
        <b-col sm="12" class="text-right">
          <b-button v-if="$can('delete', 'client') && account && client && account.organizationId == client.organizationId" size="sm" variant="danger" @click="deleteClient(client.id)">
            Delete Client
          </b-button>
        </b-col>
      </b-row>
      <life-insurance-modal @save="savePolicy" :contacts="contacts"></life-insurance-modal>
      <!-- <life-insurance-modal :id="'secondary'" @save="savePolicy" :contacts="contacts"></life-insurance-modal> -->
      <contact-modal @save="saveContact" @delete="deleteContact"></contact-modal>
    </b-col>
  </b-row>
</template>

<script>
import Assets from './components/Assets'
import CreditCards from './components/CreditCards'
import Comments from './components/Comments'
import ContactModal from '@/components/modals/ContactModal'
import LifeInsuranceModal from '@/components/modals/LifeInsuranceModal'
import Loans from './components/Loans'
import Mortgages from './components/Mortgages'
import Vehicles from './components/Vehicles'
import Dollars from '@/components/text/Dollars'
import Date from '@/components/text/Date'
import { Switch as cSwitch } from '@coreui/vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Client',
  components: {
    Assets,
    CreditCards,
    Comments,
    ContactModal,
    LifeInsuranceModal,
    Loans,
    Dollars,
    Date,
    cSwitch,
    Mortgages,
    Vehicles
  },
  data: () => {
    return {
      organizations: [
        { value: null, text: 'Select Organization...', disabled: true }
      ],
      client: {
        id: null,
        primaryContact: {
          firstName: null,
          lastName: null,
          address: {
            street: null,
            city: null,
            state: null,
            province: {
              name: null
            },
            postalCode: null,
            zipcode: null,
            country: {
              name: null
            }
          },
          income: {
            monthly: 0.00,
            additionalMonthly: 0.00
          },
          existingPolicies: []
        },
        secondaryContact: {
          income: {
            monthly: 0.00,
            additionalMonthly: 0.00
          },
          existingPolicies: []
        },
        intakeRequests: [],
        assets: [],
        creditCards: [],
        loans: [],
        mortgages: [],
        vehicles: [],
        comments: null,
        organizationId: null,
        existingPolicies: []
      },
      otherContacts: [],
      otherContactFields: [
        { key: 'name', label: 'Name', formatter: (value, key, item) => `${item.firstName} ${item.lastName} (${item.age})` },
        { key: 'type.name', label: 'Type' },
        { key: 'birthday', label: 'Birthday', formatter: (value, key, item) => `${moment(item.birthday).format('MM/DD/YYYY')}` }
      ],
      policyFields: [
        { key: 'type.name', label: 'Type' },
        { key: 'faceValue', class: 'numeric' },
        { key: 'annualPremium', class: 'numeric' },
        { key: 'cashValue', class: 'numeric' },
        { key: 'loanAmount', class: 'numeric' }
      ],
      existingPolicies: {
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'type.name', label: 'Type' },
          { key: 'faceValue', class: 'numeric' },
          { key: 'annualPremium', label: 'Premium', class: 'numeric' },
          { key: 'contextual', label: '', class: 'text-right' },
          { key: 'delete', label: '', class: 'delete' }
        ],
        types: {},
        editing: null
      },
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
      account: 'global/account'
    }),
    hasIntakeRequest () {
      let intakeRequest = this.intakeRequest
      if (intakeRequest.id > 0) {
        return true
      }
      return false
    },
    intakeRequest () {
      if (this.client.intakeRequests && this.client.intakeRequests.length == 0) {
        return {
          id: null,
          status: {
            id: null,
            name: null,
            style: null
          },
          emails: []
        }
      } else {
        let intakeRequest = this.client.intakeRequests[0]
        return intakeRequest
      }
    },
    intakeRequestedBy () {
      let intakeRequest = this.intakeRequest
      let requestedBy = null

      if (intakeRequest.createdBy && intakeRequest.createdBy.contact) {
        const contact = intakeRequest.createdBy.contact
        requestedBy = contact.firstName + ' ' + contact.lastName
      }
      return requestedBy
    },
    intakeEmailStatus () {
      let intakeRequest = this.intakeRequest
      if (intakeRequest.emails.length == 0) {
        return 'Not Sent'
      }
      const email = intakeRequest.emails[intakeRequest.emails.length -1]
      return email.status.name

    },
    intakeEmailDate () {
      let intakeRequest = this.intakeRequest
      if (intakeRequest.emails.length == 0) {
        return null
      }
      const email = intakeRequest.emails[intakeRequest.emails.length -1]
      return email.createdAt

    },
    totalIncome () {
      let total = 0.00
      if (this.client.primaryContact.income) {
        total += parseFloat(this.client.primaryContact.income.monthly) || 0.0
        total += parseFloat(this.client.primaryContact.income.additionalMonthly) || 0.0
      }

      if (this.client.secondaryContact && this.client.secondaryContact.income) {
        total += parseFloat(this.client.secondaryContact.income.monthly) || 0.0
        total += parseFloat(this.client.secondaryContact.income.additionalMonthly) || 0.0
      }
      return total
    },
    contacts () {
      let contacts = []
      contacts.push(this.client.primaryContact)
      this.otherContacts.forEach(o => contacts.push(o))
      return contacts
    }
  },
  methods: {
    termEnd (policy) {
      return moment(policy.termStartDate).add(policy.termDuration, 'years').format('MM/DD/YYYY')
    },
    formatBirthday (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    deleteClient (id) {
      if (confirm('Are you sure you want to delete this client?')) {
        this.$api.clients.delete({ id })
          .then(() => {
            this.$snotify.success('Client Deleted')
            this.$router.push({ name: 'Clients' })
          })
          .catch(() => {
            this.$snotify.error('Error Deleting Client')
          })
      }
    },
    saveComments (comments) {
      this.$set(this.client, 'comments', comments)
      this.$api.clients.update({ id: this.$route.params.id }, { comments: comments })
        .then((response) => {
          this.$set(this.client, 'comments', response.data.comments)
          this.$snotify.success('Comments Saved')
        })
        .catch(() => {
          this.$snotify.error('Error Saving Comments')
        })
    },
    saveOrganization () {
      this.$api.clients.update({ id: this.$route.params.id }, { organizationId: this.client.organizationId })
        .then(() => {
          this.$snotify.success('Organization Saved')
        })
        .catch(() => {
          this.$snotify.error('Error Saving Organization')
        })
    },
    addContact () {
      const secondaryContact = this.otherContacts.filter(o => o.contactTypeId === 2)
      let maxContactTypeId = 1
      if (secondaryContact.length > 0) {
        maxContactTypeId = 2
      }
      this.$bus.$emit('modal:editContact', {}, maxContactTypeId)
    },
    editContact (contact, isPrimaryContact) {
      const secondaryContact = this.otherContacts.filter(o => o.contactTypeId === 2)
      let maxContactTypeId = 1
      if (secondaryContact.length > 0 && contact.contactTypeId !== 2) {
        maxContactTypeId = 2
      }
      this.$bus.$emit('modal:editContact', { ...contact }, maxContactTypeId)
    },
    saveContact (contact) {
      contact.clientId = this.$route.params.id
      if (contact.id > 0) {
        this.$api.contacts.update({ id: contact.id }, contact)
          .then((response) => {
            if (contact.id === this.client.primaryContact.id) {
              this.client.primaryContact = response.data
            } else {
              this.fetchOtherContacts()
            }
            this.$snotify.success('Contact Saved')
          })
          .catch(() => {
            this.$snotify.error('Error Saving Contact')
          })
      } else {
        this.$api.contacts.create(contact)
          .then((response) => {
            this.fetchOtherContacts()
            this.$snotify.success('Contact Saved')
          })
          .catch(() => {
            this.$snotify.error('Error Saving Contact')
          })
      }
    },
    deleteContact (contact) {
      if (contact.id > 0) {
        this.$api.contacts.delete({ id: contact.id })
          .then((response) => {
            this.fetchOtherContacts()
          })
          .catch((err) => console.log(err))
      }
    },
    addPolicy () {
      this.$bus.$emit('modal:editLifeInsurance')
    },
    editPolicy (item) {
      let index = this.client.existingPolicies.indexOf(item)
      this.existingPolicies.editing = index
      this.$bus.$emit('modal:editLifeInsurance', item)
    },
    removePolicy (item) {
      if (confirm('Are you sure?')) {
        this.$api.policies.delete({ id: item.id })
          .then(() => {
            this.$store.dispatch('global/getClient', {
              id: this.$route.params.id
            })
            this.$snotify.success('Policy Deleted Successfully')
          })
          .catch(() => {
            this.$snotify.error('There was a problem removing the policy.')
          })
      }
    },
    savePolicy (value) {
      value.clientId = this.client.id
      value.existing = true
      if (value.id) {
        this.$api.policies.update({ id: value.id }, value)
          .then((response) => {
            this.$store.dispatch('global/getClient', {
              id: this.$route.params.id
            })
            this.$snotify.success('Policy Saved Successfully')
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the policy.')
          })
      } else {
        this.$api.policies.create(value)
          .then((response) => {
            this.$store.dispatch('global/getClient', {
              id: this.$route.params.id
            })
            this.$snotify.success('Policy Added Successfully')
          })
          .catch(() => {
            this.$snotify.error('There was a problem saving the policy.')
          })
      }
    },
    requestIntake () {
      if (this.client.intakeRequests && this.client.intakeRequests.length == 0) {
        let intakeRequest = {
          clientId: this.client.id
        }
        this.$api.clients.intakeRequests.create(intakeRequest)
          .then((response) => {
            this.client.intakeRequests.push(response.data)
            this.$snotify.success('Intake Request Sent')
          })
          .catch(() => {
            this.$snotify.error('Error Creating Intake Request')
          })
      }
    },
    resendIntake () {
      this.$api.clients.intakeRequests.request({ id: this.intakeRequest.id })
        .then(() => {
          this.$store.dispatch('global/getClient', {
            id: this.$route.params.id
          })
          this.$snotify.success('Email Resent')
        })
        .catch(() => {
          this.$snotify.error('Resend Failed')
        })
    },
    completeManualIntake () {
      if (this.client.intakeRequests && this.client.intakeRequests.length == 0) {
        let intakeRequest = {
          clientId: this.client.id,
          manual: true
        }
        this.$api.clients.intakeRequests.create(intakeRequest)
          .then((response) => {
            const newIntake = response.data
            this.$api.clients.intakeRequests.complete({ id: newIntake.id })
              .then(() => {
                this.$store.dispatch('global/getClient', {
                  id: this.$route.params.id
                })
                this.$snotify.success('Intake Completed')
              })
              .catch(() => {
                this.$snotify.error('Complete Intake Failed')
              })
          })
          .catch(() => {
            this.$snotify.error('Error Marking Intake Complete')
          })
      }
    },
    completeIntake () {
      this.$api.clients.intakeRequests.complete({ id: this.intakeRequest.id })
        .then(() => {
          this.$store.dispatch('global/getClient', {
            id: this.$route.params.id
          })
          this.$snotify.success('Intake Completed')
        })
        .catch(() => {
          this.$snotify.error('Complete Intake Failed')
        })
    },
    acceptIntake () {
      this.$api.clients.intakeRequests.accept({ id: this.intakeRequest.id })
        .then(() => {
          this.$store.dispatch('global/getClient', {
            id: this.$route.params.id
          })
          this.$snotify.success('Intake Accepted')
        })
        .catch(() => {
          this.$snotify.error('Accept Intake Failed')
        })
    },
    reopenIntake () {
      this.$api.clients.intakeRequests.reopen({ id: this.intakeRequest.id })
        .then(() => {
          this.$store.dispatch('global/getClient', {
            id: this.$route.params.id
          })
          this.$snotify.success('Intake Reopened')
        })
        .catch(() => {
          this.$snotify.error('Reopen Intake Failed')
        })
    },
    async fetchOtherContacts () {
      const { data: contacts } = await this.$api.contacts.clientContacts({ clientId: this.$route.params.id })
      this.otherContacts = contacts.filter(o => o.contactTypeId > 1)
    }
  },
  created () {
    this.$store.dispatch('global/clearStrategy')
    this.fetchOtherContacts()

    if (this.currentClient.id && this.currentClient.id == this.$route.params.id) {
      this.client = Object.assign(this.client, this.currentClient)
    }
    this.$store.dispatch('global/getClient', {
      id: this.$route.params.id
    })

    this.$api.accounts.organizations.get()
      .then((response) => {
        response.data.forEach((organization) => {
          this.organizations.push({ value: organization.id, text: organization.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  watch: {
    currentClient: {
      handler: function () {
        this.client = Object.assign(this.client, this.currentClient)
      },
      deep: true
    }
  }
}
</script>

<style>
  .card.clickable {
    cursor: pointer;
  }
  span.info {
    display: block;
  }
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }

</style>

<template>
  <b-modal :title="asset.id > 0 ? 'Edit Asset' : 'Add Asset'" size="lg" v-model="active" ok-title="Save" @ok="save">
    <b-row class="form-group">
      <b-col sm="4" class="mb-2">
        <label>Name</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. Ally Bank" v-model="asset.name" :state="state($v.asset.name)" @input="touch($v.asset.name)" />
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4" class="mb-2">
        <label>Type</label>
        <b-form-select :options="assetTypeOptions" v-model="asset.typeId" :state="state($v.asset.typeId)" @change="touch($v.asset.typeId) && touch($v.asset.monthlyContribution)"></b-form-select>
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4" class="mb-2" v-if="asset.type && collectBasis">
        <label>Amount Invested</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="asset.basis" :state="state($v.asset.basis)" @input="touch($v.asset.basis)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Current Value</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="asset.balance" :state="state($v.asset.balance)" @input="touch($v.asset.balance)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4" v-if="asset.type && asset.type.canContribute">
        <label>Monthly Contribution</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 100000.00" v-model="asset.monthlyContribution" :state="state($v.asset.monthlyContribution)" @input="touch($v.asset.monthlyContribution)" />
        <b-form-invalid-feedback>Required.  Enter 0 if none.</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group" v-if="asset.type && collectBasis">
      <b-col sm="12">
        <b-form-checkbox size="sm" v-model="asset.shortTerm"> Invested less than one year?</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="asset.type && asset.type.canMortgage">
      <b-col sm="12">
        <p><strong>Note: </strong> Enter the total value of your Home/Property, not equity.  We'll connect this to a mortgage in a later step if applicable.</p>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { decimal, required } from "vuelidate/lib/validators"

const requiredIf = (attribute) => {
  return (value, vm) => {
    if (vm.type) {
      if (attribute == 'collectBasis') {
        if ((vm.type.taxImplications.filter(o => o.profitOnly).length > 0) && value && value.length > 0) {
          return true
        } else if ((vm.type.taxImplications.filter(o => o.profitOnly).length == 0)) {
          return true
        }
        return false
      } else if (!vm.type[attribute]) {
        return true
      } else {
        return required(value, vm)
      }
    }
    return false
  }
}

export default {
  name: 'AssetModal',
  computed: {
    ...mapGetters({
      account: 'global/account',
      client: 'global/currentClient',
      assetTypes: 'global/assetTypes'
    }),
    assetTypeOptions () {
      if (!this.assetTypes) return []
      return [{ value: null, text: 'Select Type...', disabled: true }].concat(
        this.assetTypes.map(type => ({ value: type.id, text: type.name }))
      )
    },
    collectBasis () {
      if (this.asset.type) {
        return (this.asset.type.taxImplications.filter(o => o.profitOnly).length > 0)
      }
      return false
    }
  },
  data () {
    return {
      asset: {
        id: null,
        name: null,
        type: null,
        typeId: null,
        balance: null,
        monthlyContribution: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (asset) {
      this.active = true
      if (asset) {
        this.asset = { ...asset }
      } else {
        this.asset = {
          id: null,
          name: null,
          type: null,
          typeId: null,
          balance: null,
          monthlyContribution: null
        }
      }
    },
    save (e) {
      console.log('save')
      this.asset.monthlyContribution = this.asset.monthlyContribution || 0.0
      if (!this.$v.$invalid) {
        this.$emit('save', { ...this.asset });
      } else {
        e.preventDefault()
        this.$v.$touch()
      }
    },
  },
  created () {
    this.$bus.$on('modal:editAsset', (asset) => {
      this.$v.$reset()
      this.show(asset);
    });
  },
  watch: {
    'asset.typeId': function () {
      this.asset.type = this.assetTypes.find((e) => { return e.id == this.asset.typeId } )
    },
  },
  validations: {
    asset: {
      name: {
        required
      },
      typeId: {
        required
      },
      basis: {
        decimal,
        requiredIf: requiredIf('collectBasis')
      },
      balance: {
        decimal,
        required
      },
      monthlyContribution: {
        decimal,
        requiredIf: requiredIf('canContribute')
      }
    }
  }
}
</script>

<template>
  <b-modal :title="vehicle.id > 0 ? 'Edit Vehicle' : 'Add Vehicle'" size="lg" v-model="active" ok-title="Save" @ok="save">
    <p class="text-muted"><strong>Note:</strong> Loan months should be total months financed. Add vehicles with debt only.</p>
    <b-row class="form-group">
      <b-col sm="5">
        <label>Make</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. Toyota" v-model="vehicle.make" :state="state($v.vehicle.make)" @input="touch($v.vehicle.make)" />
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="5">
        <label>Model</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. Corolla" v-model="vehicle.model" :state="state($v.vehicle.model)" @input="touch($v.vehicle.model)" />
      </b-col>
      <b-col sm="2">
        <label>Year</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 2010" v-model="vehicle.year" :state="state($v.vehicle.year)" @input="touch($v.vehicle.year)" />
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="4">
        <label>Balance</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 500.00" v-model="vehicle.balance" :state="state($v.vehicle.balance)" @input="touch($v.vehicle.balance)" />
        <b-form-invalid-feedback>Required, greater than 0. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Minimum Payment</label>
        <b-input-group class="payment">
          <b-input type="text" placeholder="" v-model="vehicle.minimumPayment" :state="state($v.vehicle.minimumPayment)" @input="touch($v.vehicle.minimumPayment)"></b-input>
          <b-input-group-append><b-input-group-text>/mo</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Decimals Only. i.e. 100.00</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
      <b-col sm="4">
        <label>Actual Payment</label>
        <b-input-group class="payment">
          <b-input type="text" placeholder="" v-model="vehicle.actualPayment" :state="state($v.vehicle.actualPayment)" @input="touch($v.vehicle.actualPayment)"></b-input>
          <b-input-group-append><b-input-group-text>/mo</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Required. i.e. 100.00</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <label>Interest Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="vehicle.rate" :state="state($v.vehicle.rate)" @input="touch($v.vehicle.rate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Decimals Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
      <b-col sm="3">
        <label>Total Length <small>(Months)</small></label>
        <b-input-group class="length">
          <b-input type="text" placeholder="" v-model="vehicle.months" :state="state($v.vehicle.months)" @input="touch($v.vehicle.months)"></b-input>
          <b-input-group-append><b-input-group-text>mos</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Numbers Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group mt-3">
      <b-col class="form-group" sm="3">
        <label v-b-tooltip.hover title="Use Future if planning on adding a debt in the future">Timeframe <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="timeframeOptions" v-model="timeframe"></b-form-select>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Doesn't have to be exact. Month/Year if possible.">Start Date <i class="fa fa-info-circle"></i></label>
        <flat-pickr class="form-control" v-model.trim="vehicle.startAt" :config="dateConfig" placeholder="Start Date"></flat-pickr>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Use new money or reduce windcurrent for payment source">Payment Source <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="sourceOptions" v-model="vehicle.newMoney"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { decimal, integer, required } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

const greater = (compare) => {
  return (value, vm) => {
    if (value > compare) {
      return true
    } else {
      return false
    }
  }
}

export default {
  name: 'VehicleModal',
  components: {
    flatPickr
  },
  data () {
    return {
      timeframe: null,
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      timeframeOptions: [
        { value: null, text: 'Existing' },
        { value: 'future', text: 'Future' }
      ],
      sourceOptions: [
        { value: true, text: 'New Money' },
        { value: false, text: 'Wind Current' }
      ],
      vehicle: {
        id: null,
        make: null,
        model: null,
        year: null,
        startAt: null,
        balance: null,
        payment: null,
        rate: null,
        months: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (vehicle) {
      this.active = true
      if (vehicle) {
        this.vehicle = { ...vehicle }
      } else {
        this.vehicle = {
          id: null,
          make: null,
          model: null,
          year: null,
          startAt: null,
          balance: null,
          payment: null,
          rate: null,
          months: null,
          timeframe: null
        }
      }
      if (this.vehicle.startAt) {
        this.timeframe = 'future'
      } else {
        this.timeframe = null
      }
    },
    save (e) {
      this.vehicle.minimumPayment = this.vehicle.minimumPayment || 0.0
      this.vehicle.rate = this.vehicle.rate || 0.0
      this.vehicle.months = this.vehicle.months || 0

      if (typeof this.vehicle.year == 'string' && this.vehicle.year.length == 0) {
        this.vehicle.year = null
      }

      if (!this.timeframe || moment(this.vehicle.startAt) <= moment()) {
        this.vehicle.startAt = null
      }

      if (!this.$v.$invalid) {
        delete(this.vehicle.years)
        this.$emit('save', { ...this.vehicle })
      } else {
        e.preventDefault()
        this.$v.$touch()
      }
    }
  },
  created() {
    this.$bus.$on('modal:editVehicle', (vehicle) => {
      this.$v.$reset()
      this.show(vehicle);
    });
  },
  validations: {
    vehicle: {
      make: {
        required
      },
      balance: {
        required,
        decimal,
        greater: greater(0.0)
      },
      minimumPayment: {
        decimal
      },
      actualPayment: {
        required,
        decimal,
        greater: greater(0.0)
      },
      rate: {
        decimal
      },
      months: {
        integer
      }
    }
  }
}
</script>

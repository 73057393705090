<template>
  <b-row>
    <b-input-group class="mb-3" :state="state">
        <b-col sm="5">
          <b-form-select :options="months" v-model="month" :state="state"></b-form-select>
        </b-col>
        <b-col sm="3">
          <b-form-select :options="days" v-model="day" :state="state"></b-form-select>
        </b-col>
        <b-col sm="4">
          <b-form-select :options="years" v-model="year" :state="state"></b-form-select>
        </b-col>
    </b-input-group>
  </b-row>
</template>
<script>
import moment from 'moment'

export default {
  name: 'Birthday',
  props: [ 'value', 'state' ],
  data: () => {
    return {
      month: null,
      day: null,
      year: null
    }
  },
  computed: {
    months () {
      let months = []
      months.push({ value: null, text: 'Month', disabled: true })
      let date = moment().startOf('year')
      for (let i = 0; i < 12; i++) {
        months.push({
          value: date.format('MM'),
          text: date.format('MMMM')
        })
        date.add(1, 'month')
      }
      return months
    },
    days () {
      let days = []
      days.push({ value: null, text: 'Day', disabled: true })
      let date = moment(this.month, 'MM').startOf('month')
      let end = moment(date).endOf('month')
      while (date < end) {
        days.push({
          value: date.format('DD'),
          text: date.format('DD')
        })
        date.add(1, 'day')
      }
      return days
    },
    years () {
      let years = []
      years.push({ value: null, text: 'Year', disabled: true })
      let date = moment().startOf('year')

      for (let i = 0; i < 80; i++) {
        years.push({
          value: date.format('YYYY'),
          text: date.format('YYYY')
        })
        date.subtract(1, 'year')
      }
      return years
    }
  },
  created () {
    if (this.value) {
      this.month = moment(this.value).format('MM')
      this.day = moment(this.value).format('DD')
      this.year = moment(this.value).format('YYYY')
    }
  },
  watch: {
    'value': {
      handler (date) {
        if (date) {
          this.month = moment(date).format('MM')
          this.day = moment(date).format('DD')
          this.year = moment(date).format('YYYY')
        }
      }
    },
    'month': {
      handler () {
        if (this.year && this.month && this.day) {
          this.$emit('update', `${this.year}-${this.month}-${this.day}`)
        }
        this.$emit('input')
      }
    },
    'day': {
      handler () {
        if (this.year && this.month && this.day) {
          this.$emit('update', `${this.year}-${this.month}-${this.day}`)
        }
        this.$emit('input')
      }
    },
    'year': {
      handler () {
        if (this.year && this.month && this.day) {
          this.$emit('update', `${this.year}-${this.month}-${this.day}`)
        }
        this.$emit('input')
      }
    }
  }
}
</script>

<template>
  <b-modal title="Edit Contact" size="lg" v-model="active">
    <b-row class="form-group" v-if="contact.contactTypeId > 1 || contact.contactTypeId == null">
      <b-col sm="12">
        <label for="type">Type</label>
        <b-row>
          <b-col md="6">
            <b-form-select :options="contactTypesSelect" v-model="contact.contactTypeId" required></b-form-select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="form-group">
        <b-col sm="12">
            <label for="first_name">Name</label>
            <b-row>
              <b-col md="6">
                <b-form-input type="text" placeholder="First Name" v-model.trim="contact.firstName" required></b-form-input>
                <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
              </b-col>
              <b-col md="6">
                <b-form-input type="text" placeholder="Last Name" v-model.trim="contact.lastName" required></b-form-input>
                <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
              </b-col>
            </b-row>
        </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <label for="birthday">Birthday</label>
        <birthday :value="contact.birthday" @update="birthday"></birthday>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group" v-if="contact.type.address">
      <b-col sm="12">
        <label>Address</label>
        <b-row>
          <b-col md="6" class="mb-2">
            <b-form-select id="country" size="1"
              :plain="true"
              :options="countryOptions"
              v-model="contact.address.countryId">
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="12">
            <b-form-input type="text" class="form-control" placeholder="Street" v-model.trim="contact.address.street" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-input type="text" class="form-control" placeholder="City" v-model.trim="contact.address.city" />
          </b-col>
          <b-col md="3">
            <b-form-select id="province" size="1"
              :plain="true"
              :options="provinceOptions"
              v-model="contact.address.provinceId">
            </b-form-select>
          </b-col>
          <b-col md="3">
            <b-form-input type="text" class="form-control" placeholder="Postal Code" v-model.trim="contact.address.postalCode" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <label>Contact Information</label>
        <b-row>
          <b-col sm="6">
            <b-input-group class="mb-3">
              <b-input-group-prepend>
                <b-input-group-text><i class="icon-home"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" placeholder="Home Phone" v-model.trim="contact.homePhone.number" />
            </b-input-group>
          </b-col>
          <b-col sm="6">
            <b-input-group class="mb-3">
              <b-input-group-prepend>
                <b-input-group-text><i class="icon-screen-smartphone"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" placeholder="Cell Phone" v-model.trim="contact.mobilePhone.number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Email/Birthday -->
    <b-row>
      <b-col sm="6">
        <b-input-group class="mb-3">
          <b-input-group-prepend>
            <b-input-group-text>@</b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" class="form-control" placeholder="Email" autocomplete="email" v-model.trim="contact.emailAddress.address" />
          <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Income Information -->
    <h3 v-if="contact.type.monthlyIncome || contact.type.otherIncome">Income Information</h3>
    <b-row v-if="contact.type.monthlyIncome || contact.type.otherIncome">
      <b-col sm="6" v-if="contact.type.monthlyIncome">
        <b-form-group label="Monthly Take Home" label-for="monthly_income" description="Enter the amount you receive after taxes">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>$</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" class="form-control" placeholder="e.g 1000.00" v-model.trim="contact.income.monthly" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" v-if="contact.type.otherIncome">
        <b-form-group label="Additional Monthly Income" label-for="additional_monthly_income" description="Enter any additional monthly income">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>$</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" class="form-control" placeholder="e.g 1000.00" v-model.trim="contact.income.additionalMonthly" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn v-if="contact.id > 0" size="sm" class="mr-2" variant="danger" @click="deleteContact()">Delete</b-btn>
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Save
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import Birthday from '@/components/inputs/Birthday'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { mapGetters } from 'vuex'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'EditContactModal',
  components: {
    Birthday,
    flatPickr
  },
  computed: {
    countryOptions () {
      return [{ value: null, text: 'Country', disabled: true }].concat(this.countries.map(country => ({ value: country.id, text: country.name })))
    },
    provinceOptions () {
      return this.provinces
        .filter(province => province.countryId == this.contact.address.countryId)
        .map(province => ({ value: province.id, text: province.name }))
    },
    ...mapGetters({
      countries: 'global/countries',
      provinces: 'global/provinces'
    })
  },
  data () {
    return {
      minContactTypeId: 1,
      contactTypes: [],
      contactTypesSelect: [],
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      contact: {
        id: null,
        firstName: null,
        lastName: null,
        address: {
          street: null,
          city: null,
          provinceId: null,
          province: {
            name: null
          },
          postalCode: null,
          state: null,
          zipcode: null,
          countryId: null,
          country: {
            name: null
          }
        },
        birthday: null,
        age: null,
        homePhone: {
          number: null
        },
        mobilePhone: {
          number: null
        },
        workPhone: {
          number: null
        },
        emailAddress: {
          address: null
        },
        income: {
          monthly: null,
          additionalMonthly: null
        },
        contactTypeId: null,
        type: {
          id: null,
          name: null
        }
      },
      active: false,
    }
  },
  methods: {
    birthday (value) {
      this.contact.birthday = value
    },
    show (contact) {
      this.active = true
      if (contact) {
        this.contact = {
          id: contact.id || null,
          firstName: contact.firstName || null,
          lastName: contact.lastName || null,
          address: contact.address || { street: null, city: null, state: null, zipcode: null, countryId: null, provinceId: null },
          birthday: contact.birthday || null,
          age: contact.age || null,
          homePhone: contact.homePhone || { number: null },
          mobilePhone: contact.mobilePhone || { number: null },
          workPhone: contact.workPhone || { number: null },
          emailAddress: contact.emailAddress || { address: null },
          income: contact.income || { monthly: null, additionalMonthly: null },
          contactTypeId: contact.contactTypeId || null,
          type: contact.type || { id: null, name: null }
        }
      } else {
        this.contact = {
          id: null,
          firstName: null,
          lastName: null,
          address: {
            street: null,
            city: null,
            provinceId: null,
            postalCode: null,
            state: null,
            zipcode: null,
            countryId: null,
          },
          birthday: null,
          age: null,
          homePhone: {
            number: null
          },
          mobilePhone: {
            number: null
          },
          workPhone: {
            number: null
          },
          emailAddress: {
            address: null
          },
          income: {
            monthly: null,
            additionalMonthly: null
          },
          contactTypeId: null,
          type: {
            id: null,
            name: null
          }
        }
      }
    },
    deleteContact () {
      if (confirm('Are you sure you want to delete?')) {
        const contact = this.contact
        this.$emit('delete', { ...contact })
        this.active = false
      }
    },
    save () {
      const contact = this.contact
      this.$emit('save', { ...contact })
      this.active = false
    },
    async loadContactTypes () {
      const { data: contactTypes } = await this.$api.contacts.types.get()
      this.contactTypes = contactTypes
      this.contactTypesSelect = contactTypes.filter(o => o.id > this.minContactTypeId).map(o => ({ value: o.id, text: o.name }))
    }
  },
  created () {
    this.$bus.$on('modal:editContact', (contact, minContactTypeId) => {
      this.show(contact);
      this.minContactTypeId = minContactTypeId || 1
      this.loadContactTypes()
    })


  },
  watch: {
    'contact.contactTypeId': {
      handler (value) {
        const index = this.contactTypes.findIndex((o) => o.id == value)
        if (index >= 0) {
          this.contact.type = this.contactTypes[index]
        }
      }
    },
  },
  validations: {
    contact: {
      contactTypeId: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      birthday: {
        required
      }
    }
  }
}
</script>

<style>
  .flatpickr-input[readonly] {
    background: inherit;
  }
  .input-group.is-invalid > .invalid-feedback {
    display: block;
  }
  .input-group.is-invalid > input.form-control {
    border-color: #f86c6b;
  }
  .input-group.is-valid > input.form-control {
    border-color: #4dbd74;
  }
</style>

<template>
  <b-modal :title="card.id > 0 ? 'Edit Credit Card' : 'Add Credit Card'" size="lg" ok-title="Save" v-model="active" @ok="save">
    <b-row class="form-group">
      <b-col sm="6">
        <label>Bank/Issuer</label>
        <b-form-input type="text" class="form-control" v-model="card.issuer" placeholder="e.g. Chase, Bank of America..." :state="state($v.card.issuer)" @input="touch($v.card.issuer)" />
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
      <b-col sm="6">
        <label>Type</label>
        <b-form-select :options="types" v-model="card.typeId" :state="state($v.card.typeId)" @change="touch($v.card.typeId)"></b-form-select>
        <b-form-invalid-feedback>Required</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col sm="3">
        <label>Remaining Balance</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 1000.00" v-model="card.balance" :state="state($v.card.balance)" @input="touch($v.card.balance)" />
        <b-form-invalid-feedback>Required. i.e. 10000.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="3">
        <label>Minimum Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 25.00" v-model="card.minimumPayment" :state="state($v.card.minimumPayment)" @input="touch($v.card.minimumPayment)" />
        <b-form-invalid-feedback>Decimals Only. i.e. 100.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="3">
        <label>Actual Payment</label>
        <b-form-input type="text" class="form-control" placeholder="e.g. 50.00" v-model="card.actualPayment" :state="state($v.card.actualPayment)" @input="touch($v.card.actualPayment)" />
        <b-form-invalid-feedback>Required. i.e. 100.00</b-form-invalid-feedback>
      </b-col>
      <b-col sm="3">
        <label>Interest Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="card.rate" :state="state($v.card.rate)" @input="touch($v.card.rate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Valid Decimals Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col class="form-group" sm="3">
        <label v-b-tooltip.hover title="Use Future if planning on adding a debt in the future">Timeframe <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="timeframeOptions" v-model="timeframe"></b-form-select>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Doesn't have to be exact. Month/Year if possible.">Start Date <i class="fa fa-info-circle"></i></label>
        <flat-pickr class="form-control" v-model.trim="card.startAt" :config="dateConfig" placeholder="Start Date"></flat-pickr>
      </b-col>
      <b-col class="form-group" sm="3" v-show="timeframe == 'future'">
        <label v-b-tooltip.hover title="Use new money or reduce windcurrent for payment source">Payment Source <i class="fa fa-info-circle"></i></label>
        <b-form-select :options="sourceOptions" v-model="card.newMoney"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { decimal, required } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

let types = []


const lessEqual = (compare) => {
  return (value) => {
    if (value <= compare) {
      return true
    } else {
      return false
    }
  }
}

export default {
  name: 'CreditCardModal',
  components: {
    flatPickr
  },
  data () {
    return {
      timeframe: null,
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      timeframeOptions: [
        { value: null, text: 'Existing' },
        { value: 'future', text: 'Future' }
      ],
      sourceOptions: [
        { value: true, text: 'New Money' },
        { value: false, text: 'Wind Current' }
      ],
      types: [
        { value: null, text: 'Select Type...', disabled: true }
      ],
      card: {
        id: null,
        issuer: null,
        typeId: null,
        type: null,
        startAt: null,
        balance: null,
        minimumPayment: null,
        actualPayment: null,
        rate: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (creditCard) {
      this.active = true
      if (creditCard) {
        this.card = { ...creditCard }
      } else {
        this.card = {
          id: null,
          issuer: null,
          type: null,
          typeId: null,
          startAt: null,
          balance: null,
          minimumPayment: null,
          actualPayment: null,
          rate: null
        }
      }
      if (this.card.startAt) {
        this.timeframe = 'future'
      } else {
        this.timeframe = null
      }
    },
    save (e) {
      // Default fields that may be empty
      this.card.minimumPayment = this.card.minimumPayment || 0.0
      this.card.rate = this.card.rate || 0.0

      if (!this.timeframe || moment(this.card.startAt) <= moment()) {
        this.card.startAt = null
      }

      if (!this.$v.$invalid) {
        this.card.minimumPayment = parseFloat(this.card.minimumPayment)
        this.card.type = types.find((e) => { return e.id == this.card.typeId } )
        this.$emit('save', { ...this.card });
      } else {
        e.preventDefault()
        this.$v.$touch()
      }
    }
  },
  created() {
    this.$bus.$on('modal:editCreditCard', (creditCard) => {
      this.$v.$reset()
      this.show(creditCard);
    });

    this.$api.creditcards.types()
      .then((response) => {
        types = response.data

        types.forEach((type) => {
          this.types.push({ value: type.id, text: type.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  validations: {
    card: {
      issuer: {
        required
      },
      typeId: {
        required
      },
      balance: {
        decimal,
        required
      },
      minimumPayment: {
        decimal
      },
      actualPayment: {
        decimal,
        required
      },
      rate: {
        decimal,
        lessEqual: lessEqual(100)
      }
    }
  }
}
</script>

<style lang="scss">
.form-control[readonly] {
  background: inherit;
}
</style>
